<template>
  <page-wrapper class="artist">
    <h1>My vision</h1>
    <hr />
    <blockquote>
      Bees buzzing through air<br />
      look up when others look down<br />
      wet leaves in rain.<br />
    </blockquote>
    <hr />
    <p>
      I believe going outside is good for the mind. Through artwork, I bring the
      outside in. It’s in the use of natural, recycled and textured materials.
      And it is in the way that the lines are drawn. They are irregular, curved
      and often unexpected. The constant in all the work is the minimal yet
      analogue feel. Looking at my work makes you relive a nostalgic moment.
      It’s like that sultry late summer day when rain fell on warm asphalt. This
      approach has resulted in unique illustrations and bridal stationary.
    </p>

    <h1>Contact</h1>
    <hr />
    <p>
      For general inquiries, project proposals and collaborations, you can
      contact me through e-mail:
      <a href="mailto:jolande.venema@gmail.com">jolande.venema@gmail.com</a>.
    </p>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";

export default {
  metaInfo: {
    title: "About the artist",
  },
  components: {
    PageWrapper,
  },
};
</script>

<style lang="scss" scoped>
.artist {
}
</style>
